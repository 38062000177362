export enum LocationType {
  Panorama = 1,
  RainbowDeck,
  LandscapeQueue,
  LandscapeDeck,
  Hand,
  SpiritDeck,
  SpiritLine,
  SpiritInMountain,
  PlayArea,
  Evil,
  Characteristics,
  FireflyStock
}
