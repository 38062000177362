export enum Memory {
  PlayedCard = 1,
  QueueCardPlaced,
  SecondChance,
  MustEncounterSpiritOn,
  EncounterChoice,
  CardDrawn,
  FreeTurn,
  FireflyExt,
  Fireflies,
  CardToPlay
}
